.heading {
  & h2 {
    @apply relative z-10 mb-3;
  }

  & p {
    @apply relative z-10 max-w-1xl pb-8 text-base md:text-lg;
  }
}

.slide {
  @apply px-0 max-w-xxs sm:max-w-xs md:max-w-none;
}

.controllers {
  @apply flex mt-10 justify-between relative z-10;
}

.button {
  @apply z-10 w-12 h-12 ml-5 text-center bg-white rounded-full cursor-pointer;
  @apply hover:bg-black-25;

  &:disabled {
    @apply pointer-events-none border-black-50;

    & .leftArrow,
    & .rightArrow {
      @apply text-black-50;
    }
  }
}

.cta {
  @apply px-6 mr-5 md:px-24;
}

.navigation {
  @apply flex items-center;
}

.leftArrow,
.rightArrow {
  @apply w-4 h-4 mx-auto transform rotate-180;
}

.rightArrow {
  @apply rotate-0;
}

.scrollbarWrapper {
  @apply relative items-center flex-1 hidden lg:flex;
}

.scrollbar {
  @apply w-full relative h-[8px];
}

.swiperScrollbar {
  @apply bg-white;
  height: 0.125rem !important;

  &:global(.swiper-scrollbar-drag) {
    background: red !important;
  }
}

.swiperScrollbar {
  & :global(.swiper-scrollbar-drag) {
    @apply bg-good-green-100;
  }
}

.bgimage {
  @apply text-white;
}
